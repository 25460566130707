import mitt from 'mitt'

export const emitter = mitt()
export const REDIRECT_TO_LOGIN = 'REDIRECT_TO_LOGIN'
export const UPDATE_FILE_SHIPPING_INFO = 'UPDATE_FILE_SHIPPING_INFO'
export const UPDATE_CONTROL_DAY_INFO = 'UPDATE_CONTROL_DAY_INFO'
export const UPDATE_VERIFY_CLIENT_INFO = 'UPDATE_VERIFY_CLIENT_INFO'
export const UPDATE_VERIFY_PRODUCT_INFO = 'UPDATE_VERIFY_PRODUCT_INFO'
export const UPDATE_VERIFY_SKU_INFO = 'UPDATE_VERIFY_SKU_INFO'
export const UPDATE_VERIFY_UBIGEO_INFO = 'UPDATE_VERIFY_UBIGEO_INFO'
export const UPDATE_VERIFY_UM_INFO = 'UPDATE_VERIFY_UM_INFO'
