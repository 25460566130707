import { AuthResponse, VerifyResponse, RefreshTokenResponse } from '@/models/Auth'

const SERVER = 'https://server.dq.strategio.cloud'

export async function auth (email: string, password: string): Promise<AuthResponse> {
  const response = await window.fetch(`${SERVER}/auth`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json;charset=UTF-8'
    },
    body: JSON.stringify({ email, password })
  })

  if (response.status === 200) {
    const body = await response.json()
    return new AuthResponse(body)
  }

  return Promise.reject(await response.text())
}

export async function verify (currentToken: string): Promise<VerifyResponse> {
  const response = await window.fetch(`${SERVER}/auth/verify`, {
    method: 'GET',
    headers: {
      'content-type': 'application/json;charset=UTF-8',
      Authorization: `Bearer ${currentToken}`
    }
  })

  if (response.status === 200) {
    const body = await response.json()
    return new VerifyResponse(body)
  }
  return Promise.reject(await response.text())
}

export async function refreshToken (currentToken: string, currentRefreshToken: string): Promise<RefreshTokenResponse> {
  const response = await window.fetch(`${SERVER}/auth/refresh`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json;charset=UTF-8',
      Authorization: `Bearer ${currentToken}`
    },
    body: JSON.stringify({ refresh_token: currentRefreshToken })
  })

  if (response.status === 200) {
    const body = await response.json()
    return new RefreshTokenResponse(body)
  }
  return Promise.reject(await response.text())
}
