import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
Vue.use(VueRouter)

const qualityViews : Array<RouteConfig> = [
  {
    path: '/control-days',
    name: 'ControlDays',
    component: () => import('@/views/quality/control-days.vue')
  }, {
    path: '/verify-clients',
    name: 'VerifyClients',
    component: () => import('@/views/quality/verify-clients.vue')
  }, {
    path: '/verify-products',
    name: 'VerifyProducts',
    component: () => import('@/views/quality/verify-products.vue')
  }, {
    path: '/verify-ubigeos',
    name: 'VerifyUbigeos',
    component: () => import('@/views/quality/verify-ubigeos.vue')
  }, {
    path: '/verify-skus',
    name: 'VerifySkus',
    component: () => import('@/views/quality/verify-skus.vue')
  }, {
    path: '/verify-um',
    name: 'VerifyUm',
    component: () => import('@/views/quality/verify-um.vue')
  }
]

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/home-view.vue')
  },
  // {
  //   path: '/clients',
  //   name: 'Clients',
  //   component: () => import('@/views/clients-view.vue')
  // },
  // {
  //   path: '/clients/:clientid',
  //   name: 'ClientInfo',
  //   component: () => import('@/views/ClientInfo.vue')
  // },
  // {
  //   path: '/clients/:clientid/logs',
  //   name: 'ClientLogs',
  //   component: () => import('@/views/ClientLogs.vue')
  // },
  ...qualityViews,
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/login-view.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
