































import Vue from 'vue'
import UserButton from '@/components/user/UserButton.vue'

export default Vue.extend({
  data: () => ({
    drawer: false,
    items: [
      {
        header: null,
        pages: [
          { title: 'Home', link: '/', icon: 'mdi-home' },
          { title: 'Clientes', link: '/clients', icon: 'mdi-account-group' }
        ]
      }
    ]
  }),
  methods: {
    goto (route: string) {
      if (route !== this.$route.path) {
        this.$router.push(route)
      }
    }
  },
  mounted () {
    this.drawer = !this.$vuetify.breakpoint.mdAndDown
  },
  components: {
    UserButton
  }
})
