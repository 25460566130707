import { AuthController } from '@/controllers/AuthController'
import { emitter, REDIRECT_TO_LOGIN } from '@/bus'

let SERVICE_INTERVAL: number

function requestNewToken () {
  AuthController.requestNewToken()
    .then(success => {
      if (!success) {
        console.info('No se puede pedir nuevo token, enviando al login')
        emitter.emit(REDIRECT_TO_LOGIN)
      }
    })
}

export function verifyToken () {
  AuthController.verifyToken()
    .then(isValid => {
      if (!isValid) {
        console.info('token expirado, solicitando nuevo token')
        requestNewToken()
      }
    })
}

export function startService () {
  if (SERVICE_INTERVAL === undefined) {
    console.info('Starting auth service')
    SERVICE_INTERVAL = window.setInterval(() => {
      verifyToken()
    }, 60 * 1000)
  }
}

export function stopService () {
  console.info('Stoping auth service')
  window.clearInterval(SERVICE_INTERVAL)
}

// ! Agregar una variable reactiva o un bus que nos indique el estado de requestNewToken. Se necesita para evitar que hasura reciva un token expirado
