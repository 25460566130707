



























































import Vue from 'vue'

export default Vue.extend({
  data: () => ({
    menu: false,
    switchIsDark: false
  }),
  mounted () {
    this.switchIsDark = this.$vuetify.theme.dark
  },
  computed: {
    isDark () {
      return this.$vuetify.theme.dark
    }
  },
  methods: {
    changeTheme () {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark
      localStorage.dark_theme = this.$vuetify.theme.dark ? '1' : '0'
    },
    signout () {
      console.warn('sign out')
      this.menu = false
    }
  }
})
