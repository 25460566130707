














import Vue from 'vue'
import Navigation from './components/nappbars/AppNavigation.vue'
import { emitter, REDIRECT_TO_LOGIN } from '@/bus'
import { verifyToken, startService } from '@/services/auth-service'

export default Vue.extend({
  name: 'App',
  data: () => ({

  }),
  computed: {
    showNavigationBar () {
      return this.$route.path !== '/login'
    }
  },
  mounted () {
    verifyToken()
    emitter.on(REDIRECT_TO_LOGIN, this.redirectToLogin)
    startService()
  },
  beforeDestroy () {
    emitter.off(REDIRECT_TO_LOGIN, this.redirectToLogin)
  },
  methods: {
    redirectToLogin () {
      if (this.$route.path !== '/login') {
        this.$router.push('/login')
      }
    }
  },
  components: {
    Navigation
  }
})
