import { auth, refreshToken, verify } from '@/shared/authGuard'

export class AuthController {
  static token?: string | null = localStorage.getItem('token')
  static refreshToken: string | null = localStorage.getItem('refreshToken')

  // constructor () {
  //   AuthController.token = localStorage.getItem('token')
  //   AuthController.refreshToken = localStorage.getItem('refreshToken')
  // }

  static async authenticate (email: string, password: string): Promise<boolean> {
    try {
      const result = await auth(email, password)
      if (result.accessToken && result.refreshToken) {
        AuthController.token = result.accessToken
        AuthController.refreshToken = result.refreshToken

        localStorage.setItem('token', result.accessToken)
        localStorage.setItem('refreshToken', result.refreshToken)

        return true
      }
      return false
    } catch (error) {
      console.error(error)
      return false
    }
  }

  static async requestNewToken (): Promise<boolean> {
    if (!(AuthController.token && AuthController.refreshToken)) {
      return false
    }

    try {
      const result = await refreshToken(AuthController.token as string, AuthController.refreshToken as string)
      if (typeof result.accessToken === 'string') {
        AuthController.token = result.accessToken

        localStorage.setItem('token', result.accessToken)
        return true
      }
      return false
    } catch (error) {
      console.error(error)
      return false
    }
  }

  static async verifyToken (): Promise<boolean> {
    if (!(AuthController.token && AuthController.refreshToken)) {
      return false
    }

    try {
      const result = await verify(AuthController.token as string)
      return result.valid
    } catch (error) {
      console.error(error)
      return false
    }
  }
}
